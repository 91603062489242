/* tslint:disable max-line-length */
import * as React from 'react';

export function HouseForSale(...props: any[]) {
  const iconProps = props[0];
  return (
    <svg
      style={{ position: 'absolute', left: 0, top: 0 }}
      width="100%"
      height="100%"
      viewBox="0 0 679 290"
      preserveAspectRatio="none"
      {...iconProps}
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="linearGradientOne"
        >
          <stop stopColor="#61889E" offset="0%" />
          <stop stopColor="#325065" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="linearGradientTwo"
        >
          <stop stopColor="#60889E" offset="0%" />
          <stop stopColor="#325065" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(199 29)">
          <rect fill="#FFF" x="131" y="113" width="199" height="87" />
          <rect fill="#072338" x="131" y="107" width="199" height="12" />
          <polygon fill="#21394A" points="0 0 292.274336 0 327 107 0 107" />
        </g>
        <g transform="translate(32)">
          <rect fill="#FFF" x="32" y="106" width="232" height="158" />
          <polygon fill="#FFF" points="153.5 9 277 108 30 108" />
          <rect
            fill="#072338"
            transform="rotate(-39 78.91 65.427)"
            x="-17.292"
            y="59.427"
            width="192.403"
            height="12"
          />
          <rect
            fill="#072338"
            transform="rotate(-141 221.91 65.427)"
            x="125.708"
            y="59.427"
            width="192.403"
            height="12"
          />
        </g>
        <g transform="translate(214 58)">
          <rect fill="#BFBAB7" x="27" y="48" width="100" height="158" />
          <polygon fill="#BFBAB7" points="77 6 134 50 20 50" />
          <rect
            fill="#072338"
            transform="rotate(-39 42.633 36.129)"
            x="-7.367"
            y="30.129"
            width="100"
            height="12"
          />
          <rect
            fill="#072338"
            transform="rotate(-141 113.633 36.129)"
            x="63.633"
            y="30.129"
            width="100"
            height="12"
          />
        </g>
        <rect fill="#81736E" x="64" y="229" width="465" height="35" />
        <rect fill="#9C948E" x="341" y="229" width="188" height="35" />
        <rect fill="#81736E" x="369" y="165" width="132" height="99" />
        <rect fill="#BFBAB7" x="369" y="248" width="132" height="2" />
        <rect fill="#BFBAB7" x="369" y="231" width="132" height="2" />
        <rect fill="#BFBAB7" x="369" y="214" width="132" height="2" />
        <rect fill="#BFBAB7" x="369" y="197" width="132" height="2" />
        <rect fill="#BFBAB7" x="369" y="180" width="132" height="2" />
        <g transform="translate(251 229)">
          <g transform="translate(5)">
            <rect fill="#D8D8D8" width="72" height="11.333" />
            <rect fill="#9C948E" y="5.667" width="72" height="11.333" />
          </g>
          <g transform="translate(0 17)">
            <rect fill="#D8D8D8" width="82" height="11" />
            <rect fill="#9C948E" y="7" width="82" height="11" />
          </g>
        </g>
        <g transform="translate(89 47)">
          <rect
            fill="url(#linearGradientOne)"
            x="74"
            y="71"
            width="32"
            height="91"
          />
          <rect
            fill="url(#linearGradientOne)"
            x="37"
            y="71"
            width="32"
            height="91"
          />
          <rect fill="url(#linearGradientOne)" y="71" width="32" height="91" />
          <circle fill="url(#linearGradientTwo)" cx="92.5" cy="18.5" r="18.5" />
        </g>
        <g transform="translate(256 120)">
          <path
            d="M36,0 L36,0 C55.882251,-3.65231026e-15 72,16.117749 72,36 L72,109 L0,109 L0,36 C-2.4348735e-15,16.117749 16.117749,3.65231026e-15 36,0 Z"
            fill="#9C948E"
          />
          <g transform="translate(15 22)">
            <rect fill="#072338" width="42" height="87" />
            <rect
              fill="url(#linearGradientOne)"
              x="10"
              y="10"
              width="23"
              height="62"
            />
            <circle fill="#8E9395" cx="37.5" cy="48.5" r="2.5" />
          </g>
        </g>
        <g transform="translate(0 197)">
          <g transform="translate(72 15)" fill="#2BB498">
            <path d="M73.5,65 C89.7924001,65 103,65.2401154 103,49.5 C103,33.7598846 89.7924001,21 73.5,21 C57.2075999,21 44,33.7598846 44,49.5 C44,65.2401154 57.2075999,65 73.5,65 Z" />
            <ellipse cx="51" cy="35" rx="24" ry="23" />
            <ellipse cx="24" cy="23" rx="24" ry="23" />
          </g>
          <g transform="rotate(-12 131.003 9.485)" fill="#32CEAD">
            <ellipse cx="27" cy="40" rx="27" ry="26" />
            <ellipse cx="54.5" cy="28.5" rx="29.5" ry="28.5" />
            <ellipse cx="85" cy="37" rx="24" ry="23" />
            <ellipse cx="108" cy="57" rx="24" ry="23" />
            <path
              d="M57.3010804,76.8409973 C85.7437451,76.8409973 114.850429,73.6318449 114.850429,60.9292957 C114.850429,48.2267464 91.7930931,37.9292957 63.3504285,37.9292957 C34.9077639,37.9292957 11.8504285,48.2267464 11.8504285,60.9292957 C11.8504285,73.6318449 28.8584158,76.8409973 57.3010804,76.8409973 Z"
              transform="rotate(13 63.35 57.385)"
            />
          </g>
        </g>
        <g transform="translate(494 225)">
          <g transform="translate(25 1)" fill="#2BB498">
            <path d="M59.4156522,51 C71.2431903,51 80.8313043,49.7733285 80.8313043,38.46875 C80.8313043,27.1641715 71.2431903,18 59.4156522,18 C47.5881141,18 38,27.1641715 38,38.46875 C38,49.7733285 47.5881141,51 59.4156522,51 Z" />
            <ellipse cx="46.8" cy="21.969" rx="18.8" ry="17.969" />
            <ellipse cx="18.8" cy="21.969" rx="18.8" ry="17.969" />
            <ellipse cx="32" cy="14.601" rx="15" ry="14.337" />
          </g>
          <g transform="rotate(-12 84.557 6.957)" fill="#32CEAD">
            <path
              d="M28.3387855,43.877677 C41.2097816,43.877677 51.4605859,40.7057363 51.4605859,28.3961589 C51.4605859,16.0865814 41.026582,6.10769732 28.1555859,6.10769732 C15.2845898,6.10769732 4.85058594,16.0865814 4.85058594,28.3961589 C4.85058594,40.7057363 15.4677894,43.877677 28.3387855,43.877677 Z"
              transform="rotate(16 28.156 24.993)"
            />
            <path
              d="M51.7011171,52.2987056 C64.5721132,52.2987056 72.9488163,49.814487 72.9488163,37.5049096 C72.9488163,25.1953322 62.5148124,15.216448 49.6438163,15.216448 C36.7728202,15.216448 26.3388163,25.1953322 26.3388163,37.5049096 C26.3388163,49.814487 38.830121,52.2987056 51.7011171,52.2987056 Z"
              transform="rotate(8 49.644 33.758)"
            />
            <ellipse cx="51.4" cy="18.987" rx="18.96" ry="17.987" />
            <ellipse cx="64.04" cy="32.282" rx="18.96" ry="17.987" />
          </g>
        </g>
        <g transform="translate(600 195)">
          <path
            d="M39.5,27.3571429 L39.5,31.6428571"
            stroke="#4A4A4A"
            strokeLinecap="square"
          />
          <path
            d="M60.5,27.3571429 L60.5,31.6428571"
            stroke="#4A4A4A"
            strokeLinecap="square"
          />
          <g>
            <rect fill="#E2658C" x="25" y="32" width="50" height="33" />
            <rect
              stroke="#FFF"
              strokeWidth="2"
              x="26"
              y="33"
              width="48"
              height="31"
            />
          </g>
          <path d="M11,10 L15.5,0 L20,10 L20,95 L11,95 L11,10 Z" fill="#FFF" />
          <path
            d="M39,-11.6785714 L43.5,-21 L48,-11.6785714 L48,66 L39,66 L39,-11.6785714 Z"
            fill="#FFF"
            transform="rotate(90 43.5 22.5)"
          />
          <text
            fontFamily="Lato-Bold, Lato"
            fontSize="12"
            fontWeight="bold"
            fill="#FFF"
          >
            <tspan x="37.342" y="47.756">
              FOR
            </tspan>
            <tspan x="33.922" y="59.756" letterSpacing=".75">
              SALE
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export function HouseSunset() {
  return (
    <svg
      style={{ position: 'absolute', left: 0, top: 0 }}
      width="100%"
      height="100%"
      viewBox="0 0 679 290"
      preserveAspectRatio="none"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="97.391%"
          x2="50%"
          y2="0%"
          id="linearGradient-1"
        >
          <stop stopColor="#525252" offset="0%" />
          <stop stopColor="#5F5F5F" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="linearGradient-2"
        >
          <stop stopColor="#61889E" offset="0%" />
          <stop stopColor="#325065" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="linearGradient-3"
        >
          <stop stopColor="#60889E" offset="0%" />
          <stop stopColor="#325065" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="100%"
          x2="50%"
          y2="0%"
          id="linearGradient-5"
        >
          <stop stopColor="#B1CAD8" offset="0%" />
          <stop stopColor="#BFE5FF" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(199 29)">
          <rect fill="#E1E1E1" x="131" y="113" width="199" height="87" />
          <rect fill="#072338" x="131" y="107" width="199" height="12" />
          <polygon fill="#21394A" points="0 0 292.274336 0 327 107 0 107" />
        </g>
        <g transform="translate(32)">
          <rect fill="#E1E1E1" x="32" y="106" width="232" height="158" />
          <polygon fill="#E1E1E1" points="153.5 9 277 108 30 108" />
          <rect
            fill="#072338"
            transform="rotate(-39 78.91 65.427)"
            x="-17.292"
            y="59.427"
            width="192.403"
            height="12"
          />
          <rect
            fill="#072338"
            transform="rotate(-141 221.91 65.427)"
            x="125.708"
            y="59.427"
            width="192.403"
            height="12"
          />
        </g>
        <g transform="translate(214 58)">
          <rect fill="#BFBAB7" x="27" y="48" width="100" height="158" />
          <polygon fill="#BFBAB7" points="77 6 134 50 20 50" />
          <rect
            fill="#072338"
            transform="rotate(-39 42.633 36.129)"
            x="-7.367"
            y="30.129"
            width="100"
            height="12"
          />
          <rect
            fill="#072338"
            transform="rotate(-141 113.633 36.129)"
            x="63.633"
            y="30.129"
            width="100"
            height="12"
          />
        </g>
        <rect fill="#81736E" x="64" y="229" width="465" height="35" />
        <rect fill="#9C948E" x="341" y="229" width="188" height="35" />
        <rect
          fill="url(#linearGradient-1)"
          x="369"
          y="165"
          width="132"
          height="99"
        />
        <rect fill="#81736E" x="369" y="165" width="132" height="17" />
        <rect fill="#BFBAB7" x="369" y="180" width="132" height="2" />
        <g transform="translate(251 229)">
          <g transform="translate(5)">
            <rect fill="#D8D8D8" width="72" height="11.333" />
            <rect fill="#9C948E" y="5.667" width="72" height="11.333" />
          </g>
          <g transform="translate(0 17)">
            <rect fill="#D8D8D8" width="82" height="11" />
            <rect fill="#9C948E" y="7" width="82" height="11" />
          </g>
        </g>
        <g transform="translate(89 47)">
          <rect
            fill="url(#linearGradient-2)"
            x="74"
            y="71"
            width="32"
            height="91"
          />
          <rect
            fill="url(#linearGradient-2)"
            x="37"
            y="71"
            width="32"
            height="91"
          />
          <rect fill="url(#linearGradient-2)" y="71" width="32" height="91" />
          <circle fill="url(#linearGradient-3)" cx="92.5" cy="18.5" r="18.5" />
        </g>
        <g transform="translate(256 120)">
          <path
            d="M36,0 L36,0 C55.882251,-3.65231026e-15 72,16.117749 72,36 L72,109 L0,109 L0,36 C-2.4348735e-15,16.117749 16.117749,3.65231026e-15 36,0 Z"
            fill="#9C948E"
          />
          <g transform="translate(15 22)">
            <rect fill="#072338" width="42" height="87" />
            <rect
              fill="url(#linearGradient-2)"
              x="10"
              y="10"
              width="23"
              height="62"
            />
            <circle fill="#8E9395" cx="37.5" cy="48.5" r="2.5" />
          </g>
        </g>
        <g transform="translate(0 197)">
          <g transform="translate(72 15)" fill="#2BB498">
            <path d="M73.5,65 C89.7924001,65 103,65.2401154 103,49.5 C103,33.7598846 89.7924001,21 73.5,21 C57.2075999,21 44,33.7598846 44,49.5 C44,65.2401154 57.2075999,65 73.5,65 Z" />
            <ellipse cx="51" cy="35" rx="24" ry="23" />
            <ellipse cx="24" cy="23" rx="24" ry="23" />
          </g>
          <g transform="rotate(-12 131.003 9.485)" fill="#32CEAD">
            <ellipse cx="27" cy="40" rx="27" ry="26" />
            <ellipse cx="54.5" cy="28.5" rx="29.5" ry="28.5" />
            <ellipse cx="85" cy="37" rx="24" ry="23" />
            <ellipse cx="108" cy="57" rx="24" ry="23" />
            <path
              d="M57.3010804,76.8409973 C85.7437451,76.8409973 114.850429,73.6318449 114.850429,60.9292957 C114.850429,48.2267464 91.7930931,37.9292957 63.3504285,37.9292957 C34.9077639,37.9292957 11.8504285,48.2267464 11.8504285,60.9292957 C11.8504285,73.6318449 28.8584158,76.8409973 57.3010804,76.8409973 Z"
              transform="rotate(13 63.35 57.385)"
            />
          </g>
        </g>
        <g transform="translate(494 225)">
          <g transform="translate(25 1)" fill="#2BB498">
            <path d="M59.4156522,51 C71.2431903,51 80.8313043,49.7733285 80.8313043,38.46875 C80.8313043,27.1641715 71.2431903,18 59.4156522,18 C47.5881141,18 38,27.1641715 38,38.46875 C38,49.7733285 47.5881141,51 59.4156522,51 Z" />
            <ellipse cx="46.8" cy="21.969" rx="18.8" ry="17.969" />
            <ellipse cx="18.8" cy="21.969" rx="18.8" ry="17.969" />
            <ellipse cx="32" cy="14.601" rx="15" ry="14.337" />
          </g>
          <g transform="rotate(-12 84.557 6.957)" fill="#32CEAD">
            <path
              d="M28.3387855,43.877677 C41.2097816,43.877677 51.4605859,40.7057363 51.4605859,28.3961589 C51.4605859,16.0865814 41.026582,6.10769732 28.1555859,6.10769732 C15.2845898,6.10769732 4.85058594,16.0865814 4.85058594,28.3961589 C4.85058594,40.7057363 15.4677894,43.877677 28.3387855,43.877677 Z"
              transform="rotate(16 28.156 24.993)"
            />
            <path
              d="M51.7011171,52.2987056 C64.5721132,52.2987056 72.9488163,49.814487 72.9488163,37.5049096 C72.9488163,25.1953322 62.5148124,15.216448 49.6438163,15.216448 C36.7728202,15.216448 26.3388163,25.1953322 26.3388163,37.5049096 C26.3388163,49.814487 38.830121,52.2987056 51.7011171,52.2987056 Z"
              transform="rotate(8 49.644 33.758)"
            />
            <ellipse cx="51.4" cy="18.987" rx="18.96" ry="17.987" />
            <ellipse cx="64.04" cy="32.282" rx="18.96" ry="17.987" />
          </g>
        </g>
        <g transform="translate(600 199)">
          <path
            d="M39.5,27.3571429 L39.5,31.6428571"
            stroke="#4A4A4A"
            strokeLinecap="square"
          />
          <path
            d="M60.5,27.3571429 L60.5,31.6428571"
            stroke="#4A4A4A"
            strokeLinecap="square"
          />
          <g>
            <rect fill="#E2658C" x="25" y="32" width="50" height="33" />
            <rect
              stroke="#FFF"
              strokeWidth="2"
              x="26"
              y="33"
              width="48"
              height="31"
            />
          </g>
          <path d="M11,10 L15.5,0 L20,10 L20,95 L11,95 L11,10 Z" fill="#FFF" />
          <path
            d="M39,-11.6785714 L43.5,-21 L48,-11.6785714 L48,66 L39,66 L39,-11.6785714 Z"
            fill="#FFF"
            transform="rotate(90 43.5 22.5)"
          />
          <text
            fontFamily="Lato-Bold, Lato"
            fontSize="16"
            fontWeight="bold"
            fill="#FFF"
          >
            <tspan x="28.28" y="55.008">
              SOLD
            </tspan>
          </text>
        </g>
        <g transform="translate(385 192)">
          <path
            d="M4,54 L27,54 L27,64 C27,68.418278 23.418278,72 19,72 L12,72 C7.581722,72 4,68.418278 4,64 L4,54 Z"
            fill="#212121"
          />
          <path
            d="M75,54 L98,54 L98,64 C98,68.418278 94.418278,72 90,72 L83,72 C78.581722,72 75,68.418278 75,64 L75,54 Z"
            fill="#212121"
          />
          <path
            d="M20.5,22 L81.5,22 C92.8218374,22 102,31.1781626 102,42.5 L102,42.5 C102,53.8218374 92.8218374,63 81.5,63 L20.5,63 C9.17816263,63 1.38652519e-15,53.8218374 0,42.5 L0,42.5 C-1.38652519e-15,31.1781626 9.17816263,22 20.5,22 Z"
            fill="#CF4C4C"
          />
          <path
            d="M26.2876874,1.00355524e-13 L74.5558958,-1.42108547e-14 C82.1584593,-3.58647215e-14 88.5587974,5.68776512 89.4520098,13.2376753 L90.8435832,25 L10,25 L11.3915734,13.2376753 C12.2847858,5.68776512 18.6851239,1.2645892e-13 26.2876874,1.04805054e-13 Z"
            fill="#CF4C4C"
          />
          <path
            d="M27.3034823,3 L73.770774,3 C79.83833,3 84.9511931,7.52915902 85.6831402,13.5524047 L87.0742563,25 L14,25 L15.3911162,13.5524047 C16.1230633,7.52915902 21.2359264,3 27.3034823,3 Z"
            fill="url(#linearGradient-5)"
          />
          <path
            d="M61.0144618,25 C61.2732707,20.5383103 64.973427,17 69.5,17 C74.026573,17 77.7267293,20.5383103 77.9855382,25 L75.9810552,25 C75.7257427,21.6437761 72.9216149,19 69.5,19 C66.0783851,19 63.2742573,21.6437761 63.0189448,25 L61.0144618,25 Z"
            fill="#494949"
          />
          <path d="M92,35 L11,35 L92,35 Z" fill="#BFBFBF" />
          <rect fill="#494949" x="29" y="38" width="44" height="4" rx="2" />
          <rect fill="#494949" x="29" y="45" width="44" height="4" rx="2" />
          <circle fill="#FFFBC5" cx="85.5" cy="43.5" r="5.5" />
          <circle fill="#FFFBC5" cx="17.5" cy="43.5" r="5.5" />
          <path
            d="M7.00079802,18 L11.5696991,18 L11.5696991,27 L4.56969913,23 L4.21536795,22.114172 C3.60002773,20.5758215 4.34827544,18.8299101 5.88662599,18.2145699 C6.24098682,18.0728256 6.61913973,18 7.00079802,18 Z"
            fill="#CF4C4C"
          />
          <path
            d="M94.1162815,18 L90,18 L90,27 L96.3065429,23 L96.6257721,22.114172 C97.1801535,20.5758215 96.5060312,18.8299101 95.1200778,18.2145699 C94.8008218,18.0728256 94.4601307,18 94.1162815,18 Z"
            fill="#CF4C4C"
          />
        </g>
      </g>
    </svg>
  );
}

export function HouseMess() {
  return (
    <svg
      style={{ position: 'absolute', left: 0, top: 0 }}
      width="100%"
      height="100%"
      viewBox="0 0 801 439"
      preserveAspectRatio="none"
    >
      <g id="house-group">
        <g
          id="sign"
          transform="translate(64.346717, 296.346717) rotate(-135.000000) translate(-64.346717, -296.346717) translate(20.846717, 248.846717)"
        >
          <path
            id="Line-2"
            style={{ fill: 'none', stroke: '#4A4A4A', strokeLinecap: 'square' }}
            d="M50.8,17.5v4.3"
          />
          <path
            id="Line-2-Copy"
            style={{ fill: 'none', stroke: '#4A4A4A', strokeLinecap: 'square' }}
            d="M71.8,17.5v4.3"
          />
          <g id="Rectangle-5">
            <g>
              <rect
                id="path-1"
                x="44.8"
                y="13.6"
                transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 22.7134 99.9143)"
                style={{ fill: '#E2658C' }}
                width={33}
                height={50}
              />
            </g>
            <rect
              x="45.8"
              y="14.6"
              transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 22.7134 99.9143)"
              style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2 }}
              width={31}
              height={48}
            />
          </g>
          <path
            id="Combined-Shape"
            style={{ fill: '#FFFFFF' }}
            d="M22.3,0.1l4.5-10l4.5,10v85h-9V0.1z"
          />
          <path
            id="Combined-Shape-Copy-4"
            style={{ fill: '#FFFFFF' }}
            d="M89,8.1l9.3,4.5L89,17.1H11.3v-9H89z"
          />
          <text
            transform="matrix(1 0 0 1 42.1437 46.3605)"
            style={{ fill: '#FFFFFF', fontFamily: '"Lato-Bold"', fontSize: 20 }}
          >
            404
          </text>
        </g>
        <g
          id="house"
          transform="translate(376.731422, 134.264766) rotate(-181.000000) translate(-376.731422, -134.264766) translate(131.231422, 4.264766)"
        >
          <g id="garage" transform="translate(163.178539, 28.746478)">
            <rect
              id="Rectangle-10"
              x="144.5"
              y="111.6"
              style={{ fill: '#E1E1E1' }}
              width="196.8"
              height={85}
            />
            <rect
              id="Rectangle-10-Copy"
              x="144.5"
              y="105.8"
              style={{ fill: '#072338' }}
              width="196.8"
              height="11.7"
            />
            <polygon
              id="Rectangle-10-Copy-2"
              style={{ fill: '#21394A' }}
              points="15,1.3 304,1.3 338.4,105.8 15,105.8 			"
            />
          </g>
          <g id="bigstack">
            <rect
              id="Rectangle-4"
              x="46.5"
              y="105.4"
              style={{ fill: '#E1E1E1' }}
              width="228.7"
              height="154.6"
            />
            <polygon
              id="Triangle"
              style={{ fill: '#E1E1E1' }}
              points="166.3,10.5 288.1,107.3 44.6,107.3 			"
            />
            <rect
              id="Rectangle-3"
              x="-2.1"
              y="59.8"
              transform="matrix(0.7771 -0.6293 0.6293 0.7771 -20.6535 73.0239)"
              style={{ fill: '#072338' }}
              width="189.7"
              height="11.7"
            />
            <rect
              id="Rectangle-3-Copy"
              x="227.9"
              y="-29.2"
              transform="matrix(0.6293 -0.7771 0.7771 0.6293 35.6152 206.0159)"
              style={{ fill: '#072338' }}
              width="11.7"
              height="189.7"
            />
          </g>
          <g id="small-stack" transform="translate(178.397262, 56.647472)">
            <rect
              id="Rectangle-4-Copy"
              x="41.7"
              y={49}
              style={{ fill: '#BFBAB7' }}
              width="97.8"
              height="154.3"
            />
            <polygon
              id="Triangle-Copy"
              style={{ fill: '#BFBAB7' }}
              points="90.6,7.7 146.4,51 34.8,51 			"
            />
            <rect
              id="Rectangle-3-Copy-2"
              x={8}
              y="31.4"
              transform="matrix(0.7771 -0.6293 0.6293 0.7771 -10.7994 44.1703)"
              style={{ fill: '#072338' }}
              width="97.8"
              height="11.8"
            />
            <rect
              id="Rectangle-3-Copy-3"
              x="120.5"
              y="-11.6"
              transform="matrix(0.6293 -0.7771 0.7771 0.6293 17.8518 112.0937)"
              style={{ fill: '#072338' }}
              width="11.8"
              height="97.8"
            />
          </g>
          <rect
            id="foundation"
            x="45.4"
            y="226.2"
            style={{ fill: '#81736E' }}
            width="457.4"
            height="33.8"
          />
          <rect
            id="foundation-copy"
            x="318.5"
            y="226.2"
            style={{ fill: '#9C948E' }}
            width="184.3"
            height="33.8"
          />
          <linearGradient
            id="Rectangle-11_1_"
            gradientUnits="userSpaceOnUse"
            x1="913.9995"
            y1="-328.9168"
            x2="913.9995"
            y2="-329.8908"
            gradientTransform="matrix(-129.3394 -1.6821 -2.2575 96.3706 117884.7344 33491.0859)"
          >
            <stop offset={0} style={{ stopColor: '#525252' }} />
            <stop offset={1} style={{ stopColor: '#5F5F5F' }} />
          </linearGradient>
          <polygon
            id="Rectangle-11"
            style={{ fill: 'url(#Rectangle-11_1_)' }}
            points="346.4,161.9 475.8,161.9 475.8,258.3 346.4,258.3 		"
          />
          <rect
            id="Rectangle-11-Copy"
            x="346.4"
            y="161.9"
            style={{ fill: '#81736E' }}
            width="129.4"
            height="16.9"
          />
          <rect
            id="Rectangle-11-Copy-6"
            x="346.4"
            y="178.8"
            style={{ fill: '#BFBAB7' }}
            width="129.4"
            height={1}
          />
          <g id="stairs" transform="translate(213.907618, 224.898918)">
            <g id="stair" transform="translate(4.949178, 0.000000)">
              <rect
                id="Rectangle-8"
                x={15}
                y="1.3"
                style={{ fill: '#D8D8D8' }}
                width="71.3"
                height={11}
              />
              <rect
                id="Rectangle-8-Copy"
                x={15}
                y="6.8"
                style={{ fill: '#9C948E' }}
                width="71.3"
                height={11}
              />
            </g>
            <g id="stair-copy" transform="translate(0.000000, 16.546926)">
              <rect
                id="Rectangle-8_1_"
                x={15}
                y="1.3"
                style={{ fill: '#D8D8D8' }}
                width="81.2"
                height="10.7"
              />
              <rect
                id="Rectangle-8-Copy_1_"
                x={15}
                y="8.1"
                style={{ fill: '#9C948E' }}
                width="81.2"
                height="10.2"
              />
            </g>
          </g>
          <g id="windows" transform="translate(54.956503, 46.501656)">
            <linearGradient
              id="Rectangle-9-Copy-2_1_"
              gradientUnits="userSpaceOnUse"
              x1="865.2117"
              y1="-376.4142"
              x2="864.2117"
              y2="-375.4142"
              gradientTransform="matrix(-31.6818 -1.5665 -0.553 89.7486 27323.2734 35209.3203)"
            >
              <stop offset={0} style={{ stopColor: '#61889E' }} />
              <stop offset={1} style={{ stopColor: '#325065' }} />
            </linearGradient>
            <polygon
              id="Rectangle-9-Copy-2"
              style={{ fill: 'url(#Rectangle-9-Copy-2_1_)' }}
              points="88.3,71.3 119.9,71.3 119.9,161.1
                    88.3,161.1 			"
            />
            <linearGradient
              id="Rectangle-9-Copy-3_1_"
              gradientUnits="userSpaceOnUse"
              x1="865.2117"
              y1="-376.4142"
              x2="864.2117"
              y2="-375.4142"
              gradientTransform="matrix(-31.6818 -1.5665 -0.553 89.7486 27286.6348 35209.3203)"
            >
              <stop offset={0} style={{ stopColor: '#61889E' }} />
              <stop offset={1} style={{ stopColor: '#325065' }} />
            </linearGradient>
            <polygon
              id="Rectangle-9-Copy-3"
              style={{ fill: 'url(#Rectangle-9-Copy-3_1_)' }}
              points="51.6,71.3 83.3,71.3 83.3,161.1 51.6,161.1
                          "
            />
            <linearGradient
              id="Rectangle-9-Copy-4_1_"
              gradientUnits="userSpaceOnUse"
              x1="865.2117"
              y1="-376.4142"
              x2="864.2117"
              y2="-375.4142"
              gradientTransform="matrix(-31.6818 -1.5665 -0.553 89.7486 27249.998 35209.3203)"
            >
              <stop offset={0} style={{ stopColor: '#61889E' }} />
              <stop offset={1} style={{ stopColor: '#325065' }} />
            </linearGradient>
            <polygon
              id="Rectangle-9-Copy-4"
              style={{ fill: 'url(#Rectangle-9-Copy-4_1_)' }}
              points="15,71.3 46.7,71.3 46.7,161.1 15,161.1
                    "
            />
            <linearGradient
              id="Oval-2_1_"
              gradientUnits="userSpaceOnUse"
              x1="864.0696"
              y1="-379.1807"
              x2="863.0696"
              y2="-378.1807"
              gradientTransform="matrix(-36.6321 -0.6369 -0.6394 36.4912 31535.125 14388.3662)"
            >
              <stop offset={0} style={{ stopColor: '#60889E' }} />
              <stop offset={1} style={{ stopColor: '#325065' }} />
            </linearGradient>
            <path
              id="Oval-2"
              style={{ fill: 'url(#Oval-2_1_)' }}
              d="M106.6,1.3c10.1,0,18.3,8.2,18.3,18.2c0,10.1-8.2,18.2-18.3,18.2
                    s-18.3-8.2-18.3-18.2C88.3,9.4,96.5,1.3,106.6,1.3z"
            />
          </g>
          <g id="doorway" transform="translate(220.671495, 118.367852)">
            <path
              id="Rectangle-9"
              style={{ fill: '#9C948E' }}
              d="M49.6,1.3L49.6,1.3c19.1,0,34.7,15.5,34.7,34.7l0,72.8l-69.3,0l0-72.8
                    C15,16.8,30.5,1.3,49.6,1.3z"
            />
            <g id="door" transform="translate(14.443696, 21.684638)">
              <rect
                id="Rectangle-9-Copy-5"
                x={15}
                y="1.3"
                style={{ fill: '#072338' }}
                width="40.4"
                height="85.8"
              />
              <linearGradient
                id="Rectangle-9-Copy-6_1_"
                gradientUnits="userSpaceOnUse"
                x1="688.8618"
                y1="-470.7809"
                x2="687.8618"
                y2="-469.7809"
                gradientTransform="matrix(-22.1436 -1.0665 -0.3865 61.1019 15118.708 29511.418)"
              >
                <stop offset={0} style={{ stopColor: '#61889E' }} />
                <stop offset={1} style={{ stopColor: '#325065' }} />
              </linearGradient>
              <polygon
                id="Rectangle-9-Copy-6"
                style={{ fill: 'url(#Rectangle-9-Copy-6_1_)' }}
                points="24.6,11.1 46.8,11.1 46.8,72.2 24.6,72.2
                                "
              />
              <ellipse
                id="Oval-3"
                style={{ fill: '#8E9395' }}
                cx="51.1"
                cy="49.1"
                rx="2.4"
                ry="2.5"
              />
            </g>
          </g>
        </g>
        <g
          id="bush-1"
          transform="translate(728.394319, 231.766902) rotate(-345.000000) translate(-728.394319, -231.766902) translate(636.394319, 171.766902)"
        >
          <g id="bush-copy" transform="translate(74.816393, 16.080969)">
            <path
              id="Oval-4"
              style={{ fill: '#2BB498' }}
              d="M62.5,70.2c17.1,0,31,0.2,31-16s-13.9-29.5-31-29.5c-17.1,0-31,13.2-31,29.5
                    C31.5,70.4,45.4,70.2,62.5,70.2z"
            />
            <ellipse
              id="Oval-4-Copy-2"
              transform="matrix(2.444561e-06 1 -1 2.444561e-06 78.0056 0.2413)"
              style={{ fill: '#2BB498' }}
              cx="38.9"
              cy="39.1"
              rx="23.8"
              ry="25.2"
            />
            <ellipse
              id="Oval-4-Copy-3"
              transform="matrix(2.444561e-06 1 -1 2.444561e-06 37.1995 16.2197)"
              style={{ fill: '#2BB498' }}
              cx="10.5"
              cy="26.7"
              rx="23.8"
              ry="25.2"
            />
          </g>
          <g
            id="bush"
            transform="translate(77.742642, 59.735147) rotate(-12.000000) translate(-77.742642, -59.735147) translate(8.242642, 13.735147)"
          >
            <ellipse
              id="Oval-4_1_"
              transform="matrix(2.214326e-06 1 -1 2.214326e-06 54.5265 27.8055)"
              style={{ fill: '#32CEAD' }}
              cx="13.4"
              cy="41.2"
              rx="26.9"
              ry="28.4"
            />
            <ellipse
              id="Oval-4-Copy"
              transform="matrix(2.214326e-06 1 -1 2.214326e-06 71.5479 -13.0092)"
              style={{ fill: '#32CEAD' }}
              cx="42.3"
              cy="29.3"
              rx="29.5"
              ry={31}
            />
            <ellipse
              id="Oval-4-Copy-2_1_"
              transform="matrix(2.214326e-06 1 -1 2.214326e-06 112.4137 -36.2888)"
              style={{ fill: '#32CEAD' }}
              cx="74.4"
              cy="38.1"
              rx="23.8"
              ry="25.2"
            />
            <ellipse
              id="Oval-4-Copy-3_1_"
              transform="matrix(2.214326e-06 1 -1 2.214326e-06 157.2895 -39.785)"
              style={{ fill: '#32CEAD' }}
              cx="98.5"
              cy="58.8"
              rx="23.8"
              ry="25.2"
            />
            <path
              id="Oval-4-Copy-4"
              style={{ fill: '#32CEAD' }}
              d="M40.9,77.3c29.1,6.7,59.7,10.4,62.7-2.4c3-12.8-18.3-28.6-47.4-35.4
                    S0.9,37.7-2,50.5S11.7,70.6,40.9,77.3z"
            />
          </g>
        </g>
        <g
          id="bush-2"
          transform="translate(301.376588, 361.056080) rotate(-30.000000) translate(-301.376588, -361.056080) translate(238.876588, 318.556080)"
        >
          <g id="bush-copy_1_" transform="translate(29.216030, 1.155570)">
            <path
              id="Oval-4-Copy-2_2_"
              style={{ fill: '#2BB498' }}
              d="M56.9,50.6c13.8,0,25-1.4,25-14.5c0-13.1-11.2-23.7-25-23.7
                    s-25,10.6-25,23.7S43.1,50.6,56.9,50.6z"
            />
            <ellipse
              id="Oval-4-Copy-3_2_"
              transform="matrix(-1 2.341061e-06 -2.341061e-06 -1 84.4041 34.0407)"
              style={{ fill: '#2BB498' }}
              cx="42.2"
              cy={17}
              rx={22}
              ry="20.8"
            />
            <ellipse
              id="Oval-4-Copy-5"
              transform="matrix(-1 2.341061e-06 -2.341061e-06 -1 18.9602 34.0408)"
              style={{ fill: '#2BB498' }}
              cx="9.5"
              cy={17}
              rx={22}
              ry="20.8"
            />
            <ellipse
              id="Oval-4-Copy-6"
              transform="matrix(-1 2.341061e-06 -2.341061e-06 -1 49.8123 17.0122)"
              style={{ fill: '#2BB498' }}
              cx="24.9"
              cy="8.5"
              rx="17.5"
              ry="16.6"
            />
          </g>
          <g
            id="bush_1_"
            transform="translate(54.742088, 42.309277) rotate(-12.000000) translate(-54.742088, -42.309277) translate(6.242088, 9.809277)"
          >
            <path
              id="Oval-4_2_"
              style={{ fill: '#32CEAD' }}
              d="M16.6,39.1c14.5,4.1,27,3.9,30.9-9.7S42.9,1.3,28.4-2.9S-0.9,0.7-4.8,14.4
                    S2.2,35,16.6,39.1z"
            />
            <path
              id="Oval-4-Copy_1_"
              style={{ fill: '#32CEAD' }}
              d="M46.9,49.8c14.9,2.1,25,0.6,27-13.5c2-14.1-8.5-27.2-23.4-29.3
                    S21.9,14.6,20,28.7S32,47.7,46.9,49.8z"
            />
            <ellipse
              id="Oval-4-Copy-2_3_"
              transform="matrix(-1 2.110826e-06 -2.110826e-06 -1 99.1802 22.3218)"
              style={{ fill: '#32CEAD' }}
              cx="49.6"
              cy="11.2"
              rx="22.2"
              ry="20.8"
            />
            <ellipse
              id="Oval-4-Copy-3_3_"
              transform="matrix(-1 2.110826e-06 -2.110826e-06 -1 128.7235 53.048)"
              style={{ fill: '#32CEAD' }}
              cx="64.4"
              cy="26.5"
              rx="22.2"
              ry="20.8"
            />
          </g>
        </g>
        <g
          id="car"
          transform="translate(558.000000, 380.000000) rotate(-270.000000) translate(-558.000000, -380.000000) translate(498.000000, 338.000000)"
        >
          <path
            id="Rectangle-19"
            style={{ fill: '#212121' }}
            d="M3.7,78h27.1v13c0,4.4-3.6,8-8,8H11.7c-4.4,0-8-3.6-8-8V78z"
          />
          <path
            id="Rectangle-19-Copy"
            style={{ fill: '#212121' }}
            d="M87.2,78h27.1v13c0,4.4-3.6,8-8,8H95.2c-4.4,0-8-3.6-8-8V78z"
          />
          <path
            id="Rectangle-18"
            style={{ fill: '#CF4C4C' }}
            d="M22.9,40.7h72.2c13.2,0,23.9,10.7,23.9,23.9l0,0c0,13.2-10.7,23.9-23.9,23.9
                H22.9C9.7,88.5-1,77.8-1,64.6l0,0C-1,51.4,9.7,40.7,22.9,40.7z"
          />
          <path
            id="Rectangle-18-Copy-4"
            style={{ fill: '#CF4C4C' }}
            d="M27.6,15h61.5c7.6,0,14,5.7,14.9,13.2l1.9,15.9H10.8l1.9-15.9
                C13.6,20.7,20,15,27.6,15z"
          />
          <linearGradient
            id="winshield_1_"
            gradientUnits="userSpaceOnUse"
            x1="293.987"
            y1="879.5923"
            x2="293.987"
            y2="878.5923"
            gradientTransform="matrix(0 -25.6667 -85.9697 0 75676.7578 7589.833)"
          >
            <stop offset={0} style={{ stopColor: '#B1CAD8' }} />
            <stop offset={1} style={{ stopColor: '#BFE5FF' }} />
          </linearGradient>
          <path
            id="winshield"
            style={{ fill: 'url(#winshield_1_)' }}
            d="M29.2,18.5h58.4c6.1,0,11.2,4.5,11.9,10.5l1.9,15.1h-86L17.3,29
                C18.1,23,23.2,18.5,29.2,18.5z"
          />
          <path
            id="Combined-Shape_1_"
            style={{ fill: '#494949' }}
            d="M70.8,44.2c0.3-5.2,4.7-9.3,10-9.3c5.3,0,9.7,4.1,10,9.3h-2.4
                c-0.3-3.9-3.6-7-7.6-7c-4,0-7.3,3.1-7.6,7H70.8z"
          />
          <path
            id="Rectangle-18-Copy"
            style={{ fill: '#BFBFBF' }}
            d="M107.2,55.8H11.9H107.2z"
          />
          <path
            id="Rectangle-20"
            style={{ fill: '#494949' }}
            d="M35.5,59.3h47.1c1.3,0,2.3,1,2.3,2.3v0c0,1.3-1,2.3-2.3,2.3H35.5
                c-1.3,0-2.3-1-2.3-2.3v0C33.1,60.4,34.2,59.3,35.5,59.3z"
          />
          <path
            id="Rectangle-20-Copy"
            style={{ fill: '#494949' }}
            d="M35.5,67.5h47.1c1.3,0,2.3,1,2.3,2.3v0c0,1.3-1,2.3-2.3,2.3H35.5
                c-1.3,0-2.3-1-2.3-2.3v0C33.1,68.5,34.2,67.5,35.5,67.5z"
          />
          <ellipse
            id="Oval-7-Copy"
            transform="matrix(2.535182e-06 1 -1 2.535182e-06 165.338 -33.8384)"
            style={{ fill: '#FFFBC5' }}
            cx="99.6"
            cy="65.8"
            rx="6.4"
            ry="6.5"
          />
          <ellipse
            id="Oval-7-Copy-2"
            transform="matrix(2.535182e-06 1 -1 2.535182e-06 85.3382 46.1616)"
            style={{ fill: '#FFFBC5' }}
            cx="19.6"
            cy="65.8"
            rx="6.4"
            ry="6.5"
          />
          <path
            id="Rectangle-22"
            style={{ fill: '#CF4C4C' }}
            d="M6.5,36h6.1v10.5l-8.2-4.7l-0.7-1.7c-0.6-1.5,0.1-3.3,1.7-3.9
                C5.7,36.1,6.1,36,6.5,36z"
          />
          <path
            id="Rectangle-22-Copy"
            style={{ fill: '#CF4C4C' }}
            d="M109.7,36h-4.8v10.5l7.4-4.7l0.4-1c0.7-1.8-0.1-3.8-1.8-4.5
                C110.5,36.1,110.1,36,109.7,36z"
          />
        </g>
      </g>
    </svg>
  );
}
/* tslint:enable max-line-length */
