import * as React from 'react';

export function Lawn(...props: any[]) {
  const iconProps = props[0];

  return (
    <svg
      style={{ position: 'absolute', left: 0, top: 0 }}
      width="100%"
      height="100%"
      viewBox="0 0 1439 553"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...iconProps}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="100%"
          x2="50%"
          y2="0%"
          id="linear-gradient"
        >
          <stop stopColor="#2BB498" offset="0%" />
          <stop stopColor="#31C8A9" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M1439,80 C1263.5263,33.0216441 1007.05329,0 720.5,0 C433.654758,0 175.48582,32.938439 0,80 L0,553 L1439,553 L1439,80 Z"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  );
}

export function DarkLawn(...props: any[]) {
  const iconProps = props[0];

  return (
    <svg
      style={{ position: 'absolute', left: 0, top: 0 }}
      width="100%"
      height="100%"
      viewBox="0 0 1439 553"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...iconProps}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="100%"
          x2="50%"
          y2="0%"
          id="linear-gradient-dark"
        >
          <stop stopColor="#243F56" offset="0%" />
          <stop stopColor="#244057" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M1439,80 C1263.5263,33.0216441 1007.05329,0 720.5,0 C433.654758,0 175.48582,32.938439 0,80 L0,800.484375 L1439,800.484375 L1439,80 Z"
          fill="url(#linear-gradient-dark)"
        />
      </g>
    </svg>
  );
}
